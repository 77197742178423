@import '../../resources/media-queries.scss';

.spending{
    &__account-box{
        margin-bottom: 100px;
    }
    @include tablet{

    }
    @include desktop{
        
    }
}