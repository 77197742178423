@import '../../resources/media-queries.scss';

.profile{
    @include tablet{

    }
    @include desktop{
      display: flex;
      flex-direction: row;
    }
}
