@import '../../resources/media-queries.scss';

.cashings{
    &__search-unit{
        flex-wrap: wrap;
    }
    @include tablet{
        &__submit{
            width:45%;
        }
    }
    @include desktop{
        &__full-search{
            display: flex;
            flex-direction: row;
        }
        &__search-unit{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width:90%;
            position: relative;
            left:10px;
            
        }
        &__search-box{
            width:16%;
        }
    }
}