@import '../../resources/media-queries.scss';

.agent{
    &__container{
        padding:20px;
    }
    &__title{
        padding:10px 0 5px;
        margin-bottom: 20px!important;
        color: #222;
        font-size: 22px;
        font-weight: 400;
        border-bottom: 2px solid #90c923;
    }
    &__logo{
        height:40px;
        width:130px;
        position: relative;
        top:0;
        margin:0 auto;
        display: flex;
        padding-bottom: 20px;
    }
    &__form{
        width:100%;
    }
    &__label{
        font-size: 14px;
        line-height: 16px;
        color: #48525D;
        font-weight: bold;
        padding:20px 0 8px;
    }
    &__input{
        background: #FFFFFF;
        border-radius: 7px;
        border: 1px solid #E6E8EB;
        height:36px;
        width:100%;
    }
    &__submit-button{
        width: 160px;
        height: 38px;
        background: #90c923;
        border-radius: 7px;
        border:none;
        margin-top: 20px;
    }
    &__button-writing{
        color: #F6F7FA;
        font-size: 12px;
        line-height: 20px;
    }
    @include tablet{
        &__container{
            padding:40px;
        }
        &__form{
            width:70%;
        }
        &__title{
            width:70%;
        }
    }
    @include desktop{
        display: flex;
        flex-direction: row;
        &__page-content{
            width:80%;
        }
        &__title{
            width:25%;
        }
        &__logo{
            display: block;
            margin:0;
        }
        &__container{
            padding:40px 0 40px 60px;
        }
        &__form{
            width:60%;
        }
    }
}
