@import '../../resources/media-queries.scss';

.cashing{
    &__buttons-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        div{
            width:47%;
            height: 40px;
            border:1px solid #90c923;
            border-radius: 7px;
            color: #90c923;
            background:#fff;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            cursor: pointer;
        }

    }
    @include tablet{

    }
    @include desktop{
        &__double-unit{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__unit{
            display: flex;
            flex-direction: column;
            width:47%;
        }
        &__buttons-box{
            margin:20px 0;
        }
    }
}