@import '../../resources/media-queries.scss';

.mobile-menu{
    background-color: #90c923;
    height:60px;
    padding-bottom: 0;
    margin-bottom: 0;
    position: fixed;
    bottom: 0;
    width:100%;
    z-index: 11;
    &__list{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width:100%;
        padding:20px 0 0;
        margin:0;
        
    }
    &__img, &__account-img{
        width:30px;
    }
    &__item{
        color:#fff;
        font-weight: lighter;
        border-right: 1px solid #fff;
        padding-right:10px;
    }

    @include desktop{
        display: none;
    }
}