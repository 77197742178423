@import './media-queries.scss';

h1, h2, h3, h4, h5, p, li{
    margin:0;
    font-family: 'Roboto', sans-serif
}

.standard{    
    &__link{
        text-decoration: none;
        color:initial;
        cursor: pointer;
    }
    button{
        cursor: pointer;
    }
    button:focus{
        outline:none;
    }
    &__only-desktop{
        display: none;
    }
    input:focus{
        outline: none;
    }
    @include tablet{

    }

    @include desktop{
        &__only-desktop{
          display: block;
        }
        &__only-mobile{
          display: none;
        }
        &__title-box{
          display: flex;
          flex-direction: column;
        }
    }
}
