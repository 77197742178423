@import '../../resources/media-queries.scss';

.agents{
    white-space: wrap;
    overflow-x: scroll;
    position: relative;
    &__page-content{
      
    }
    &__table{
        width:150%;
     }
    &__add{
        background-color: #90c923;
        color: #fff;
        width: 160px;
        height: 40px;
        margin: 20px 0 0;
        font-size: 14px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        position: fixed;
        right:20px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom:150px;
        img{
            width:18px;
            height:20px;
            position: relative;
            
            right:5px;
        }
    }

     &__heading{
         font-weight: bold;
         color:#222;
     }

     &__info{
         border-bottom: 1px solid red;
     }
     @include tablet{
        &__table{
            width:100%;
        }
        &__add{
            right:100px;
            bottom:400px;
        }
     }
     @include desktop{
        &__table{
            width:60%;
            margin-top: 40px;
        }
        &__add{
            display: none;
        }
        &__add-button{
            background-color: #90c923;
            color: #fff;
            width: 160px;
            height: 40px;
            margin: 20px 0 0;
            font-size: 14px;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right:20%;
            img{
                width:18px;
                height:20px;
                position: relative;
                
                right:5px;
            }
        }
        &__heading{
            padding-bottom: 10px;
            border-bottom: 1px solid #90c923;
        }
        &__row{
            padding-bottom: 15px;
        }
     }
}