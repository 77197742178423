@import '../../resources/media-queries.scss';

.add-tranzaction{
    position: relative;
    padding:30px 0 20px;
   
    &__close-icon{
        width:20px;
        position: absolute;
        right:0;
        top:20px;
        cursor: pointer;
    }
    &__title{
        color: #000000;
        font-size:22px;
        padding-bottom: 10px;
    }
    &__info{
        font-size: 14px;
        line-height: 22px;
        color: #0C0C0F;
        padding:10px 0 20px;
        line-height: 1.27;
    }
    &__label{
        color:#404040;
        font-size: 14px;
        padding:25px 0 5px;
    }
    &__field{
        background: #FFFFFF;
        border-radius: 7px;
        border: 1px solid #E6E8EB;
        height: 36px;
        width: 100%;
    }
    &__submit-buttons{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        button{
            height:48px;
            border-radius: 5px;
            margin:10px 0;
        }
    }
    &__submit-button{
        background-color:#90c923;
        border:none;
        h5{
            color:#ffff;
            font-size: 14px;
            font-weight: lighter;
        }
    }
    &__submit-button:hover{
        background-color: #90c923;
    }
    &__cancel{
        background:transparent;
        box-shadow: 0px 2px 10px rgba(31, 33, 32, 0.1);
        border: 1px solid #DBE7E9;
        h5{
            font-size: 14px;
        }
    }
    &__cancel:hover{
        background-color: #eff4f5;
    }
    &__termen-button{
        background-color: #90c923;
        border:none;
        height:48px;
        border-radius: 5px;
        margin:10px 0;
        width:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        h5{
            font-size: 14px;
            font-weight: lighter;
            color:#fff;
            text-align: center;
        }
    }
    &__termen-button:hover{
        background-color: #90c923;
    }
    &__select-box{
        width:100%!important;
    }
    @include tablet{

    }
    @include desktop{
        padding:20px 60px;
    }
}