@import '../../resources/media-queries.scss';

.menu{
    display: none;
    @include desktop{
        min-height: 100vh;
        display: block;
        background: #90c923;
        width:15%;
        &__title{
            color: #fff;
            padding:40px 0 0 40px;
            font-size: 24px;
        }
        &__item-box{
            display: flex;
            flex-direction: row;
        }
        &__img{
            width:26px;
            height:26px;
            position: relative;
            top:20px;
        }
        &__list{
            display: flex;
            flex-direction: column;
            margin:60px auto 40px;
            list-style-type: none;
        }
        &__item-name{
            font-size: 18px;
            line-height: 17px;
            color: #fff;
            margin-bottom: 20px!important;
            padding:25px 0 10px 8px;
        }
        &__item-name:hover{
            border-bottom: 1px solid #fff;
        }
        &__account-box{
            display: flex;
            flex-direction: row;
            margin:100% 0 0 40px;
            cursor: pointer;
        }
        &__account-img{
            width:20px;
            height:20px;
            position: relative;
            top:20px;
        }
    }
}