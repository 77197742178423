
@import '../../resources/media-queries.scss';
.attachment-form {
    &__upload-files {
        border:1px dashed #90c923;
        margin:10px 0;
        padding:10px 20px;
        display: flex;
        background:#fff;
        flex-direction: column;
        cursor: pointer;
    }
    &__instruction{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__upload{
        font-size: 16px;
        line-height: 1.6;
        color: #130f36;
    }
    &__file-list{
        list-style-type: none;
    }
    &__existing-files {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #0d0d0d;
      font-weight: 500;
    }
    &__upload-button{
        background: #90c923;
        border:none;
        margin:20px auto;
        border-radius: 5px;
        color:#fff;
        width:200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height:40px;
    
        font-weight: bold;
        h5{
            font-size: 14px;
            font-weight: lighter;
            padding-right: 5px;
        }
    }

    &__upload-icon{
        width:20px;
        height:20px;
    }
    &__delete-button{
        border:none;
        border-radius: 3px;
        background-color:  #cc0000;
        color:#fff;
        padding:5px 10px;
        margin-left: 40px;
        width:100px;

    }
    @include desktop{
        &__upload-button{
            width:250px!important;
            margin:20px 0 10px!important;
        }
        &__delete-button{
            margin-left: 200px;
            padding:10px 15px;
            cursor: pointer;
        }
    }
  }

  .drop-instruction{
      display: flex;
      flex-direction: row;
  }
