@import '../../resources/media-queries.scss';

.spendings{
    &__search-area{
        background: #f5fbe9;
        padding:20px;
        border-radius: 5px;
    }
    &__search-title{
        display: flex;
        flex-direction: row;
        cursor: pointer;
        justify-content: space-between;
        div{
            display: flex;
            flex-direction: row;
            h3{
                color: #697B8A;
                font-size: 20px;
                font-weight: lighter;
            }
        }

    }
    &__top-search-icon{
        width:20px;
        height:20px;
        margin-left: 5px;
        position: relative;
        top:2px;
    }
    &__arrow-icon{
        width:16px;
        height:16px;
        margin-left: 5px;
    }
    &__form{

    }
    &__search-unit{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__select{
        background: #FFFFFF;
        border-radius: 7px;
        border: 1px solid #E6E8EB;
        height: 36px;
        width: 100%;
    }
    &__select:focus{
        outline:none;
    }
    &__search-box{
        width:45%;
    }
    &__submit-button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width:122px;
        height:36px;
        position: relative;
        top:20px;

        img{
            width:18px;
            height:18px;
            margin-right: 5px;
        }
    }
    &__wrapper{
        margin:40px 0;
        width:150%;
    }
    &__full-wrapper{
        white-space: wrap;
        overflow-x: scroll;
        position: relative;
    }
    &__table-title{
        padding-bottom: 20px;
    }
    &__heading{
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 16px;
        color: #48525D;
    }
    &__row{
        display: flex;
        flex-direction: row;

        margin:10px 0;
        padding-bottom: 5px;
        border-bottom:1px solid #f5fbe9;
    }
    &__first-cell{
        width:120px!important;
        h4{
            color: #48525D;
            font-size: 12px;
            line-height: 14px;
        }
    }
    &__head-title{
        width:250px;
    }
    &__cell-name{
        width:400px!important;
    }
    &__cell{
        width:250px;
        img{
            width:18px;
            height:18px;
            cursor: pointer;
            margin-right: 19px;
        }
        h4{
            color: #48525D;
            font-size: 12px;
            line-height: 14px;
        }
    }
    &__add{
        bottom:70px;
    }
    &__number h4{
        background-color: #f5fbe9;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width:30px;
        height:30px;
        color:#90c923!important;
        position: relative;
        bottom:5px;

    }
    @include tablet{
        &__wrapper{
            width:100%;
        }
    }
    @include desktop{
        &__search-title{
            width:100%;
        }
        &__form{
            width:100%;
        }
        &__search-box-full{
            width: 25%;
        }
        &__search-box{
            width:48%;
        }
        &__search-unit{
            width:30%;
            position: relative;
            right:20px;
        }
        &__full-search{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__last-cell{
            right:70px;
        }
        &__wrapper{
          width:100%;
        }
        &__full-wrapper{
            white-space: nowrap;
            overflow-x: hidden;
        }
        &__row{
            padding-bottom: 0;
            margin:15px 0;
        }
        &__heading{
            margin-bottom: 30px;
            font-size: 16px;
        }
        &__table-title{
            text-align: center;
            padding-bottom: 40px;
            font-size: 24px;
        }
        &__cell h4{
            font-size: 14px;
        }
    }
}
